import React, { useState } from "react"
import Meta from "../../components/meta"
import Layout from "../../components/layout"
import logo from "../../images/favicon.png"

import RoundedButton from "../../components/rounded-button"
import "./index.css"

function ContactUsThankYou() {
    return (
        <Layout>
            <div className="contactThankYouWrapper">
                <div className="contactThankYouWrapper__container">
                    <div className="contactThankYouWrapper__container__text">
                        <h1>
                            Thank you <br />
                            for contacting us!
                        </h1>
                        <p>Your inquiry has been received.</p>
                    </div>
                    <RoundedButton href="/" text="Return to Home " />
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => {
    // console.log("blogs: ", blogs);

    let meta = {
        title: "Contact Thank You",
        description: "Thank you for contacting us! Your inquiry has been received. Return to Home Page Thank you for contacting us! Your inquiry has been received. Return to Home Page",
        url: "https://www.digitallaboratory.net/contact-us-thank-you/",
        image: logo
    };

    return <Meta meta={meta} />
}

export default ContactUsThankYou
